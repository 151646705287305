import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { emailIdValidation } from '../../utilities/validators/emailid.validation';
import { contactNumberValidator } from '../../utilities/validators/mobileNumber.validation';

const SendOtpForm = (props) => {
  const {
    forgotPasswordRef,
    register2,
    reset2,
    errors2,
    handleSubmit2,
    getValues2,
    onClickSendOTPButtonHandler,
    onClickCancelButtonOfForgot,
  } = props;

  return (
    <div>
      <Form
        style={{ display: 'none' }}
        onSubmit={handleSubmit2(onClickSendOTPButtonHandler)}
        ref={forgotPasswordRef}
      >
        <div className='mb-4'>
          <Form.Group>
            <Form.Control
              type='number'
              placeholder='Mobile No.'
              onKeyPress={(e) => {
                if (e.key === 'e' || e.key === '-') {
                  e.preventDefault();
                }
              }}
              {...register2('mobile', {
                valueAsNumber: true,
                validate: (value) =>
                  contactNumberValidator(value) || 'Number Should be 10-Digit!',
              })}
            />
            {errors2.mobile && (
              <small className='text-danger'>{errors2.mobile.message}</small>
            )}
          </Form.Group>
        </div>
        <div className='mb-4'>
          <Form.Group>
            <Form.Control
              type='email'
              placeholder='Email Id'
              {...register2('email', {
                validate: (value) =>
                  emailIdValidation(value) || 'Invalid Email!',
              })}
            />
            {errors2.email && (
              <small className='text-danger'>{errors2.email.message}</small>
            )}
          </Form.Group>
        </div>
        <div className='d-flex align-content-center'>
          <Button
            className='px-4 btn login-button'
            onClick={handleSubmit2(onClickSendOTPButtonHandler)}
          >
            Send OTP
          </Button>

          <Button
            className='px-4 btn cancel-button ms-3'
            onClick={(event) => onClickCancelButtonOfForgot(event)}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SendOtpForm;
