import axiosInstanceWithoutToken from "../../utilities/axiosConfig/axios.withoutToken.config";

// login handler
export const LoginHelper = async (data) => {
  return await axiosInstanceWithoutToken
    .post('/users/login', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// forgot password
export const ForgotPassHelper = async (data) => {
  return await axiosInstanceWithoutToken
    .post('/users/sendOtpToUser', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Verify Password
export const VerifyPassHelper = async (data) => {
  return await axiosInstanceWithoutToken
    .post('/users/resetPassword', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
