import React, { lazy, Suspense, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SuspenseFallback from "../components/SuspenseFallback";
import Login from "../pages/login";

// Master Component
const LazyRole = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/roleMaster")), 1000);
  });
});

const LazyDepartment = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/departmentMaster")), 1000);
  });
});

const LazySubDepartment = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/master/subDepartmentMaster")),
      1000
    );
  });
});

const LazyDesignation = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/master/designationMaster")),
      1000
    );
  });
});

const LazyUserLevel = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/userlevelMaster")), 1000);
  });
});

const LazyMenu = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/menuMaster")), 1000);
  });
});

const LazyAgencyMaster = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/agencyMaster")), 1000);
  });
});

const LazyWorkTypeMaster = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/workTypeMaster")), 1000);
  });
});

const LazyUnitsMaster = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/unitMaster")), 1000);
  });
});

const LazyRoleMenu = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/roleMenu")), 1000);
  });
});

const LazyLicense = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/master/licenseMaster")), 1000);
  });
});

// Dashboard Component
const LazyHomeDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/dashBoard/HomeDashboard")), 1000);
  });
});

const LazyprojectDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/dashBoard/projectDashboard")),
      1000
    );
  });
});

const LazyfinanceDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/dashBoard/FinanceDashboard2")),
      1000
    );
  });
});

const LazyfinanceDashBoardIOS = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/dashBoard/FinanceDashboard")),
      1000
    );
  });
});

const LazyissueDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/dashBoard/issueDashboard")),
      1000
    );
  });
});

const LazytestDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/dashBoard/testDashboard")), 1000);
  });
});

const LazyPanipanchayatDashboard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/dashBoard/PanipanchayatDashboard")),
      1000
    );
  });
});

const LazyAgricultureDashBoard = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/AgriDashBoard")), 1000);
  });
});

// Normal Component
const LazyActivityListing = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/activity tree")), 1000);
  });
});

const LazyProjectImplementation = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/project Implementation")), 1000);
  });
});

const LazyUser = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/user")), 1000);
  });
});

const LazyIssue = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/issuePermission")), 1000);
  });
});

const LazyVendorListing = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/vendorContractManagement")),
      1000
    );
  });
});

const LazyInvoiceExpenditure = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/invoiceExpenditure")), 1000);
  });
});

const LazyContract = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/contractManagement")), 1000);
  });
});

const LazyFisheries = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Fisheries")), 1000);
  });
});

const LazyAgriculture = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/Agriculture&horticulture")),
      1000
    );
  });
});

const LazyBlock = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/BPR")), 1000);
  });
});

const LazyEDemonstration = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/eDemonstration")), 1000);
  });
});

const LazyWorkProgress = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/workProgressMonitoring")), 1000);
  });
});

const LazySurveyData = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/surveyData")), 1000);
  });
});

const LazyTank = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/tank")), 1000);
  });
});

const LazyTender = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/tender")), 1000);
  });
});

const LazyProjectWork = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/projectWorkMonitoring")), 1000);
  });
});

const LazyEstimate = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/estimateManagement")), 1000);
  });
});

const LazyProject = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/project")), 1000);
  });
});

const LazyHydrology = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/hydrology")), 1000);
  });
});

const LazyGrievance = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/grievance")), 1000);
  });
});

const LazyEnvironment = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Env/index")), 1000);
  });
});

const LazyScreening = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Screening/index")), 1000);
  });
});

const LazySocial = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Social/index")), 1000);
  });
});


const LazyClosureDeviation = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/ClosureDeviation/index")), 1000);
  });
});

const LazyHumanResource = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/HR/index")), 1000);
  });
});

const LazyApicol = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Apicol/index")), 1000);
  });
});

const LazyAssetSurvey = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Assetmapping/index")), 1000);
  });
});

const LazyDemonstrationSurvey = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/DemonstrationMap/index")), 1000);
  });
});

const LazyBenificiaries = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("../pages/BenificiariesMapping/index")),
      1000
    );
  });
});

const LazyApicolExpense = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/ApicolExpense/index")), 1000);
  });
});

const LazyPaniPanchayat = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/PaniPanchayat/index")), 1000);
  });
});

const LazyAgriHortiAgency = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/Agri&HortiAgency/index")), 1000);
  });
});

const LazyAllExpenditure = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("../pages/AllExpenditure/index")), 1000);
  });
});

const Routing = () => {
  return (
    <>
      <Router>
        <Suspense fallback={<SuspenseFallback />}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route
              path="/projectimplement"
              exact
              component={LazyProjectImplementation}
            />
            <Route path="/users" exact component={LazyUser} />

            {/* Master Routes */}
            <Route path="/rolemaster" exact component={LazyRole} />
            <Route
              path="/designationmaster"
              exact
              component={LazyDesignation}
            />

            <Route path="/departmentmaster" exact component={LazyDepartment} />
            <Route path="/issue" exact component={LazyIssue} />
            <Route
              path="/subdepartmentmaster"
              exact
              component={LazySubDepartment}
            />
            <Route path="/userlevelmaster" exact component={LazyUserLevel} />
            <Route path="/menumaster" component={LazyMenu} />
            <Route path="/agencymaster" component={LazyAgencyMaster} />
            <Route path="/worktypemaster" component={LazyWorkTypeMaster} />
            <Route path="/unitmaster" component={LazyUnitsMaster} />
            <Route path="/rolemenu" component={LazyRoleMenu} />
            <Route path="/license" component={LazyLicense} />
            <Route path="/activitytree" exact component={LazyActivityListing} />
            <Route
              path="/vendormanagement"
              exact
              component={LazyVendorListing}
            />
            <Route
              path="/invoiceexpenditure"
              exact
              component={LazyInvoiceExpenditure}
            />
            <Route path="/contract" exact component={LazyContract} />
            <Route path="/agrihorti" exact component={LazyAgriculture} />
            <Route
              path="/agrihortidashboard"
              exact
              component={LazyAgricultureDashBoard}
            />
            <Route path="/assetsurvey" exact component={LazyAssetSurvey} />
            <Route
              path="/demonstartionsurvey"
              exact
              component={LazyDemonstrationSurvey}
            />
            <Route path="/bpr" exact component={LazyBlock} />
            <Route
              path="/edemonstration"
              exact
              component={LazyEDemonstration}
            />
            <Route path="/workprogress" exact component={LazyWorkProgress} />
            <Route
              path="/projectdashBoard"
              exact
              component={LazyprojectDashboard}
            />
            <Route
              path="/financedashBoard"
              exact
              component={LazyfinanceDashboard}
            />
            <Route
              path="/openfinancedashBoard2"
              exact
              component={LazyfinanceDashBoardIOS}
            />
            <Route
              path="/issueDashboard"
              exact
              component={LazyissueDashboard}
            />
            <Route
              path="/panipanchyatdashboard"
              exact
              component={LazyPanipanchayatDashboard}
            />
            <Route path="/dashBoard" exact component={LazyHomeDashboard} />
            <Route path="/testDashboard" exact component={LazytestDashboard} />
            <Route path="/surveydata" exact component={LazySurveyData} />
            <Route path="/tank" exact component={LazyTank} />
            <Route path="/tender" exact component={LazyTender} />
            <Route path="/projectwork" exact component={LazyProjectWork} />
            <Route path="/estimate" exact component={LazyEstimate} />
            <Route path="/projects" exact component={LazyProject} />
            <Route path="/hydrology" exact component={LazyHydrology} />
            <Route path="/grievance" exact component={LazyGrievance} />
            <Route path="/fisheries" exact component={LazyFisheries} />
            <Route
              path="/environment"
              exact
              component={LazyEnvironment}
            />
            <Route
              path="/screening"
              exact
              component={LazyScreening}
            />
            <Route
              path="/environment"
              exact
              component={LazyEnvironment}
            />
            <Route
              path="/social"
              exact
              component={LazySocial}
            />
            <Route
              path="/closuredeviation"
              exact
              component={LazyClosureDeviation}
            />
            <Route path="/humanresource" exact component={LazyHumanResource} />
            <Route path="/apicoldata" exact component={LazyApicol} />
            <Route
              path="/benificiariesmapping"
              exact
              component={LazyBenificiaries}
            />
            <Route path="/apicolexpense" exact component={LazyApicolExpense} />
            <Route
              path="/panipanchayatdata"
              exact
              component={LazyPaniPanchayat}
            />
            <Route
              path="/agrihortiagency"
              exact
              component={LazyAgriHortiAgency}
            />
            <Route
              path="/allexpenditure"
              exact
              component={LazyAllExpenditure}
            />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};
export default Routing;
