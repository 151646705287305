export const contactNumberValidator = (number) => {
  var contactNumberLength = number.toString().length;
  if (contactNumberLength != 10 && !isNaN(number)) {
    return false;
  } else {
    return true;
  }
};

export const aadharNumberValidator = (number) => {
  var contactNumberLength = number.toString().length;
  if (contactNumberLength != 12 && !isNaN(number)) {
    return false;
  } else {
    return true;
  }
};
